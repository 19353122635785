// To see this message, add the following to the `<head>` section in your

console.log('OKNOTOK')

// Example: Load Rails libraries in Vite.
//
import * as Turbo from '@hotwired/turbo'
Turbo.start()
//
// import ActiveStorage from '@rails/activestorage'
// ActiveStorage.start()
//
// // Import all channels.
// const channels = import.meta.globEager('./**/*_channel.js')

// Example: Import a stylesheet in app/frontend/index.css
//import '~/entrypoints/gallery.scss'
//import lightbox from '~/lightbox';
//import frontpage  from "~/frontpage.vue";
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.min.js'
import Dropzone from 'dropzone';
import 'dropzone/dist/dropzone.css';
import './photoswipe_init.js';
import './masonry_init.js';
import '~/stylesheets/public.scss';
import '~/stylesheets/galleries.scss';
import '~/stylesheets/gallery.scss';